
import { dispatchFinishRegister, dispatchUserEdit } from '@/store/main/actions';
import { readIsLoggedIn } from '@/store/main/getters';
import { Component, Vue } from 'vue-property-decorator';
import { mask } from 'vue-the-mask';

@Component({
  directives: {mask},
  components: {
  },
})

export default class SignUpNursery extends Vue {
  public pets: boolean = false;
  public agree: boolean = false;

  public document: any = null;
  public title: string = '';
  public firstName: string = '';
  public lastName: string = '';
  public middleName: string = '';
  public phone: string = '';
  public region: string = '';
  public city: string = '';
  public deliveryAddress: string = '';

  public formError = false;
  public loadingState = {
    submit: false,
    page: true,
  };

  public mounted() {
    const isLoggedIn = readIsLoggedIn(this.$store);
    if (!isLoggedIn) {
      this.$router.push('/sign-up');
    }
    this.loadingState.page = false;
  }

  public handleFileChange(event) {
    this.document = event.target.files[0];
  }

  public generatePayload() {
    const formData = new FormData();
    formData.append("title", this.title);
    formData.append("first_name", this.firstName);
    formData.append("last_name", this.lastName);
    formData.append("middle_name", this.middleName);
    formData.append("phone", this.phone);
    formData.append("region", this.region);
    formData.append("city", this.city);
    formData.append("delivery_address", this.deliveryAddress);
    formData.append("documents", this.document);
    return formData;
  }

  public async handleSubmit() {
    if (!this.firstName || !this.lastName || !this.title || !this.phone || !this.region || !this.city) {
      this.formError = true;
      // @ts-ignore
      this.$toast.error(this.$t('Пожалуйста, заполните все поля'), {
        duration: 3000, position: 'bottom-right',
      });
      return false;
    }
    this.formError = false;
    this.loadingState.submit = true;
    const payload = this.generatePayload();
    const response = await dispatchUserEdit(this.$store, payload);
    dispatchFinishRegister(this.$store);
    this.$router.push('/home');
    this.loadingState.submit = false;
  }
}
